<template>
  <main class="table-font ">
    <h1>Transaction Reversal</h1>
    <form
      @submit.prevent="getTransactionLogs"
      class="flex items-center mt-5 gap-5"
    >
      <input
        class="flex rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 h-9 w-80"
        placeholder="Transaction Reference"
        v-model="transactionRef"
        required
      />
      <button
        :disabled="isLoading"
        :class="isLoading ? 'opacity-50 cursor-not-allowed' : ''"
        class="items-center justify-center text-sm font-medium transition-colors bg-blue-400 text-white hover:bg-blue-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background border border-input hover:bg-accent hover:text-accent-foreground px-3 rounded-md hidden h-8 lg:flex"
        type="submit"
        aria-haspopup="menu"
        aria-expanded="false"
        data-state="closed"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-4 h-4 mr-3"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <line x1="21" x2="14" y1="4" y2="4"></line>
          <line x1="10" x2="3" y1="4" y2="4"></line>
          <line x1="21" x2="12" y1="12" y2="12"></line>
          <line x1="8" x2="3" y1="12" y2="12"></line>
          <line x1="21" x2="16" y1="20" y2="20"></line>
          <line x1="12" x2="3" y1="20" y2="20"></line>
          <line x1="14" x2="14" y1="2" y2="6"></line>
          <line x1="8" x2="8" y1="10" y2="14"></line>
          <line x1="16" x2="16" y1="18" y2="22"></line>
        </svg>
        Submit
      </button>
    </form>

    <Modal
      size="max-w-xl"
      v-if="openModal"
      @close="
        openModal = false
        ;(transactionRef = ''), (status = '')
      "
    >
      <div>
        <div class="re">
          <h1 class="text-center text-2xl font-bold mt-8  capitalize">
            Request Successful:
            <span
              :class="
                status.requestSuccessful ? 'bg-green-400' : 'text-red-400'
              "
            >
              {{ status.requestSuccessful }}
            </span>
          </h1>
          <h1 class="text-center text-2xl font-bold mt-8">
            Response Code :
            <span
              :class="
                status.requestSuccessful ? 'bg-green-400' : 'text-red-400'
              "
            >
              {{ status.responseCode }}
            </span>
          </h1>
          <h1 class="text-center text-2xl font-bold mt-8">
            Response Message :
            <span
              :class="
                status.requestSuccessful ? 'bg-green-400' : 'text-red-400'
              "
            >
              {{ status.responseMessage }}
            </span>
          </h1>
        </div>
      </div>
    </Modal>
  </main>
</template>

<script>
import moment from 'moment'
import Modal from '../components/Modal.vue'

export default {
  name: 'Loans',
  data() {
    return {
      transactionRef: '',
      isLoading: false,
      openModal: false,
      status: '',
    }
  },
  components: {
    Modal,
  },
  methods: {
    async getTransactionLogs() {
      this.isLoading = true
      try {
        let res = await this.$store.dispatch(
          'REVERSE_TRANSACTION',
          this.transactionRef
        )
        this.status = res.data.items
        this.openModal = true
        return res
      } catch (error) {
        console.log(error, 'error')
      } finally {
        this.isLoading = false
      }
    },
  },
  async mounted() {
    this.dateRange = [
      moment()
        .startOf('day')
        .toDate(),
      moment().toDate(),
    ]
  },
}
</script>
